// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

// Create the context
const AuthContext = createContext();

// Provider component
export const AuthProvider = ({ children }) => {
  const {
    user,
    isAuthenticated,
    logout,
    isLoading,
    loginWithPopup,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const [isAuthLoading, setAuthLoading] = useState(true);
  const [userCredit, setUserCredit] = useState(0);
  const [userCreditUsed, setUserCreditUsed] = useState(0);
  const [isFetchingCredit, setIsFetchingCredit] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);
  const [isSubUser, setIsSubUser] = useState(false);
  const [manageSubUsers, setManageSubUser] = useState(false);
  const [userRegistered, setUserRegistered] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [apiEndpoint, setApiEndpoint] = useState("users");

  const tier3 = ["price_1MJqPELkgjk38j5rxeSp7AEo", "price_1P29AjLkgjk38j5rRIfjhSwc", "price_1QFc062LftaZnN8oeIchHZoW", "price_1QNt0D2LftaZnN8owtjU2CsC"];

  const tier = {
    "1": "Starter",
    "2": "Premium",
    "3": "Business",
  }

  const color = {
    "1": "success",
    "2": "info",
    "3": "primary",
  }

  const burnUserCredit = async () => {
    const maxRetries = 5;
    let retryCount = 0;

    if (!isAuthenticated) {
      toast.error("Please sign in to start the free trial with search credits.");
      return false;
    }

    const payload = {
      subId: userSubscription?.data?.sub_id || null,
      customerId: userSubscription?.data?.customer_id || null,
      subUserId: userSubscription.subUserId || null,
    };

    while (retryCount < maxRetries) {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`https://mtech-api.com/${apiEndpoint}/subscription/useCredit`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          setUserCredit(prevCredit => prevCredit - 1); // Update credit count
          setUserCreditUsed(prevCreditUsed => prevCreditUsed + 1);
          return true;
        } else {
          const errorData = await response.text();
          if (errorData === "No trial credits left") {
            toast.error("No trial credits left. Please subscribe to continue.");
            return false;
          } else if (retryCount + 1 === maxRetries) {
            toast.error("You have no credits left.");
            return false;
          }
        }
      } catch (error) {
        console.error(`Attempt ${retryCount + 1} failed:`, error);
      }
      retryCount++;
      await new Promise(resolve => setTimeout(resolve, 1000)); // Retry delay
    }
  };

  useEffect(() => {
    if (!isLoading) {
      console.log(user);
      setAuthLoading(false);
    }
  }, [isLoading]);
  // Function to handle login
  const handleLogin = async () => {
    try {
      setAuthLoading(true);
      await loginWithPopup();
    } catch (error) {
      toast.error("Login failed", error);
    } finally {
      setAuthLoading(false);
    }
  };

  // Fetch subscription details
  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      if (isAuthenticated && user) {
        try {
          setIsFetchingCredit(true);
          const token = await getAccessTokenSilently();

          // Step 1: Fetch user details to get `stripe_customer_id`
          let userResponse = await fetch(
            `https://mtech-api.com/${apiEndpoint}?userid=${user?.email}`,
            {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Register user if needed
          if (!userResponse.ok) {
            userResponse = await fetch(
              `https://mtech-api.com/users-smart-hs?userid=${user?.email}`,
              {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (!userResponse.ok) {
              const userRegisterResponse = await fetch(
                `https://mtech-api.com/users-smart-hs/stripe/customer/create?email=${user?.email}&name=${user?.name}`,
                {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }

              );

              if (userRegisterResponse.ok) {
                setUserRegistered((prev) => !prev);
                setUserInfo(await userRegisterResponse.json());
              }
            }
          }

          const userData = await userResponse.json();
          setUserInfo(userData);
          const stripeCustomerId = userData?.data?.stripe_customerid;

          // Step 2: Use `stripe_customer_id` for `/subscription/current` and other API requests
          const [subscriptionResponse, oldSubsResponse] = await Promise.all([
            fetch(
              `https://mtech-api.com/users-smart-hs/subscription/current-alt?userid=${user?.email}&customerid=${stripeCustomerId}`,
              {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
            fetch(
              `https://mtech-api.com/${apiEndpoint}/subscription/current?userid=${user?.email}&customerid=${stripeCustomerId}`,
              {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
          ]);

          let subscriptionData = null;
          let userCredit = 0;
          let userCreditUsed = 0;

          // Process subscription responses
          if (subscriptionResponse.ok) {
            setApiEndpoint('users-smart-hs');
            const data = await subscriptionResponse.json();
            userCredit = data.data.credits_purchased - data.data.credits_used;

            if (isNaN(userCredit)) {
              userCredit = data.data.credits_given - data.data.credits_used;
            }

            subscriptionData = {
              ...data,
              detail: userInfo,
              tierName: tier[data.data.tier],
              tierColor: color[data.data.tier],
            };
            if (tier3.includes(data.data.curr_price_id)) {
              setManageSubUser(true);
            }
            userCreditUsed = parseInt(subscriptionData?.data?.credits_used);
          } else if (oldSubsResponse.ok) {
            const data = await oldSubsResponse.json();
            userCredit = data.data.credits_purchased - data.data.credits_used;

            if (isNaN(userCredit)) {
              userCredit = data.data.credits_given - data.data.credits_used;
            }

            subscriptionData = {
              ...data,
              detail: userInfo,
              tierName: tier[data.data.tier],
              tierColor: color[data.data.tier],
            };
            if (tier3.includes(data.data.curr_price_id)) {
              setManageSubUser(true);
            }
            userCreditUsed = parseInt(subscriptionData?.data?.credits_used);
          } else {
            setApiEndpoint('users-smart-hs');
          }

          if (userCredit === 0) {
            // Sub-user check
            const [checkSubResponse, checkSubOldResponse] = await Promise.all([
              fetch(
                `https://mtech-api.com/users-smart-hs/subscription/check_sub_user?user_email=${user?.email}`,
                {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
              fetch(
                `https://mtech-api.com/users/subscription/check_sub_user?user_email=${user?.email}`,
                {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);

            if (checkSubResponse.ok) {
              const checkSubData = await checkSubResponse.json();

              // If sub-user, fetch main user subscription data
              if (checkSubData?.data?.main_sub_id) {
                setApiEndpoint('users-smart-hs');

                const [mainUserResponse] = await Promise.all([
                  fetch(
                    `https://mtech-api.com/users-smart-hs/subscription/main_user_credits?sub_id=${checkSubData.data.main_sub_id}`,
                    {
                      method: "GET",
                      headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  ),
                ]);

                if (mainUserResponse.ok) {
                  const mainUserData = await mainUserResponse.json();
                  subscriptionData = {
                    ...mainUserData,
                    tierName: tier[mainUserData.data.tier],
                    tierColor: color[mainUserData.data.tier],
                  };
                  userCredit = mainUserData.data.credits_purchased - mainUserData.data.credits_used;

                  userCreditUsed = parseInt(checkSubData.data.credits_used, 10);
                  setIsSubUser(true);
                }
              }
            } else if (checkSubOldResponse.ok) {
              const checkSubData = await checkSubOldResponse.json();

              // If sub-user, fetch main user subscription data
              if (checkSubData?.data?.main_sub_id) {
                const [mainUserResponse] = await Promise.all([
                  fetch(
                    `https://mtech-api.com/users/subscription/main_user_credits?sub_id=${checkSubData.data.main_sub_id}`,
                    {
                      method: "GET",
                      headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  ),
                ]);

                if (mainUserResponse.ok) {
                  const mainUserData = await mainUserResponse.json();
                  subscriptionData = {
                    ...mainUserData,
                    tierName: tier[mainUserData.data.tier],
                    tierColor: color[mainUserData.data.tier],
                  };
                  userCredit = mainUserData.data.credits_purchased - mainUserData.data.credits_used;

                  userCreditUsed = parseInt(checkSubData.data.credits_used, 10);
                  setIsSubUser(true);
                }
              }
            } else if (userCredit === 0) {
              const [trialSubsResponse] = await Promise.all([
                fetch(
                  `https://mtech-api.com/users-smart-hs/subscription/trial?userid=${user.email}&customerid=${userData.data.stripe_customerid}`,
                  {
                    method: "GET",
                    headers: {
                      "content-type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                ),
              ]);

              if (trialSubsResponse.ok) {
                const trialSubsData = await trialSubsResponse.json();
                userCredit = parseInt(trialSubsData.data.credits_given) - parseInt(trialSubsData.data.credits_used);
                userCreditUsed = parseInt(trialSubsData.data.credits_used);
                subscriptionData = {
                  data: {
                    customer_id: trialSubsData.data.customer_id,
                  }
                }
              }
            }
          }

          setUserSubscription(subscriptionData);
          setUserCredit(userCredit);
          setUserCreditUsed(userCreditUsed || 0);
        } catch (error) {
          setUserCredit(0);
        } finally {
          setIsFetchingCredit(false);
        }
      }
    };

    fetchSubscriptionDetails();
  }, [isAuthenticated, user, getAccessTokenSilently, userRegistered]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        logout,
        isAuthLoading,
        userCredit,
        userCreditUsed,
        handleLogin,
        loginWithRedirect,
        isFetchingCredit,
        userSubscription,
        getAccessTokenSilently,
        burnUserCredit,
        isSubUser,
        userInfo,
        manageSubUsers,
        apiEndpoint,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for easy access to AuthContext
export const useAuth = () => useContext(AuthContext);
